import React from "react";

class About extends React.Component {
  componentDidMount() {
    var jsElm = document.createElement("script");
    jsElm.src = "js/main.js";
    document.body.appendChild(jsElm);
  }
  render = () => {
    return (
      <>
        <section
          className="hero-wrap hero-wrap-2"
          style={{ backgroundImage: 'url("images/about1.jpg")' }}
        >
          <div className="overlay" />
          <div className="container">
            <div className="row no-gutters slider-text align-items-center justify-content-center">
              <div className="col-md-9 ftco-animate text-center">
                <h1 className="mb-2 bread">About Us</h1>
                <p className="breadcrumbs">
                  <span className="mr-2">
                    <a href="/home">
                      Home <i className="ion-ios-arrow-forward" />
                    </a>
                  </span>
                  <span>
                    About Us <i className="ion-ios-arrow-forward" />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="ftco-section">
          <div className="container">
            <div className="row d-flex">
              <div className="col-md-5 order-md-last wrap-about align-items-stretch">
                <div className="wrap-about-border">
                  <div
                    className="img"
                    style={{ height: "inherit", marginBottom: 0 }}
                  >
                    <img
                      src="images/newAi.png"
                      alt="img"
                      style={{ width: "inherit" }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-7 wrap-about pr-md-4 ftco-animate">
                <p>
                  Quick serve IT Solution (QSIT) is a leading new-age IT product
                  development firm armed with bunch of a strong team of
                  full-stack data scientists, data engineers, and app developers
                  with a core focus on Data Science, Artificial Intelligence and
                  Machine Learning technologies. With an award-winning skills
                  and platform, we craft and deliver applications that are
                  designed with utmost precision & beauty.
                </p>
                <p>
                  QSIT Solutions has the expertise & skills to tackle your
                  toughest business problems at scale.
                </p>
                <p>
                  Having a clear objective to solve complex issues for
                  customers, QSIT Solutions specializes in high-end
                  mobile/gaming apps, outsourced product development, custom
                  software research & development across most relevant
                  technologies including Java, PHP, Ruby on Rails, iOS, Mac and
                  Android and also in building super intelligent software
                  solutions using BD technologies, Machine Learning and AI.
                </p>
                <h2 className="mb-4">Being Skeptical ?</h2>
                <p>
                  It's okay to being skeptical. We understand that It's your
                  "business". But, we assure you that your business is in right
                  hands. We have been directly dealing with clients and
                  understanding their requirements from their perspective and
                  target audience for these many years. And deliver what is
                  expected as an end product. We work on different module get
                  tested, show it our clients and work on it until our client
                  gets satisfied. So we can assure you, Don't worry. Cause as it
                  is your business this is our business and what matters the
                  most to us is your satisfaction.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="ftco-section ftco-counter">
          <div className="container">
            <div className="row justify-content-center mb-5 pb-2 d-flex">
              <div className="col-md-6 align-items-stretch d-flex">
                <div
                  className="img img-video d-flex align-items-center"
                  style={{ backgroundImage: "url(images/about.jpg)" }}
                >
                  <div className="video justify-content-center">
                    <a
                      href="https://vimeo.com/45830194"
                      className="icon-video popup-vimeo d-flex justify-content-center align-items-center"
                    >
                      <span className="ion-ios-play" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 heading-section ftco-animate pl-lg-5 pt-md-0 pt-5">
                <h2 className="mb-4">We Are the Best Consulting Agency</h2>
                <p>
                  Quick serve IT Solution (QSIT) is a leading new-age IT product
                  development firm armed with bunch of a strong team of
                  full-stack data scientists, data engineers, and app developers
                  with a core focus on Data Science, Artificial Intelligence and
                  Machine Learning technologies. With an award-winning skills
                  and platform, we craft and deliver applications that are
                  designed with utmost precision & beauty.
                </p>
                <p>
                  QSIT Solutions has the expertise & skills to tackle your
                  toughest business problems at scale.
                </p>
                <p>
                  Having a clear objective to solve complex issues for
                  customers, QSIT Solutions specializes in high-end
                  mobile/gaming apps, outsourced product development, custom
                  software research & development across most relevant
                  technologies including Java, PHP, Ruby on Rails, iOS, Mac and
                  Android and also in building super intelligent software
                  solutions using BD technologies, Machine Learning and AI.
                </p>
                <p></p>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section
          className="ftco-intro ftco-no-pb img"
          style={{ backgroundImage: "url(images/bg_3.jpg)" }}
        >
          <div className="container">
            <div className="row justify-content-center mb-5">
              <div className="col-md-10 text-center heading-section heading-section-white ftco-animate">
                <h2 className="mb-0">You Always Get the Best Guidance</h2>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="ftco-counter" id="section-counter">
          <div className="container">
            <div className="row d-md-flex align-items-center justify-content-center">
              <div className="wrapper">
                <div className="row d-md-flex align-items-center">
                  <div className="col-md d-flex justify-content-center counter-wrap ftco-animate">
                    <div className="block-18">
                      <div className="icon">
                        <span className="flaticon-doctor" />
                      </div>
                      <div className="text">
                        <strong className="number" data-number={10}>
                          0
                        </strong>
                        <span>Projects Completed</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md d-flex justify-content-center counter-wrap ftco-animate">
                    <div className="block-18">
                      <div className="icon">
                        <span className="flaticon-doctor" />
                      </div>
                      <div className="text">
                        <strong className="number" data-number={10}>
                          0
                        </strong>
                        <span>Satisfied Customer</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md d-flex justify-content-center counter-wrap ftco-animate">
                    <div className="block-18">
                      <div className="icon">
                        <span className="flaticon-doctor" />
                      </div>
                      <div className="text">
                        <strong className="number" data-number={2}>
                          0
                        </strong>
                        <span>Awwards Received</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md d-flex justify-content-center counter-wrap ftco-animate">
                    <div className="block-18">
                      <div className="icon">
                        <span className="flaticon-doctor" />
                      </div>
                      <div className="text">
                        <strong className="number" data-number={5}>
                          0
                        </strong>
                        <span>Years of Experienced</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="ftco-section testimony-section">
          <div className="container">
            <div className="row justify-content-center mb-5">
              <div className="col-md-8 text-center heading-section ftco-animate">
                <h2 className="mb-4">Our Clients Says</h2>
                <p>
                  Separated they live in. A small river named Duden flows by
                  their place and supplies it with the necessary regelialia. It
                  is a paradisematic country
                </p>
              </div>
            </div>
            <div className="row ftco-animate justify-content-center">
              <div className="col-md-12">
                <div className="carousel-testimony owl-carousel">
                  <div className="item">
                    <div className="testimony-wrap d-flex">
                      <div
                        className="user-img"
                        style={{ backgroundImage: "url(images/person_1.jpg)" }}
                      />
                      <div className="text pl-4">
                        <span className="quote d-flex align-items-center justify-content-center">
                          <i className="icon-quote-left" />
                        </span>
                        <p>
                          Far far away, behind the word mountains, far from the
                          countries Vokalia and Consonantia, there live the
                          blind texts.
                        </p>
                        <p className="name">Racky Henderson</p>
                        <span className="position">Father</span>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="testimony-wrap d-flex">
                      <div
                        className="user-img"
                        style={{ backgroundImage: "url(images/person_2.jpg)" }}
                      />
                      <div className="text pl-4">
                        <span className="quote d-flex align-items-center justify-content-center">
                          <i className="icon-quote-left" />
                        </span>
                        <p>
                          Far far away, behind the word mountains, far from the
                          countries Vokalia and Consonantia, there live the
                          blind texts.
                        </p>
                        <p className="name">Henry Dee</p>
                        <span className="position">Businesswoman</span>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="testimony-wrap d-flex">
                      <div
                        className="user-img"
                        style={{ backgroundImage: "url(images/person_3.jpg)" }}
                      />
                      <div className="text pl-4">
                        <span className="quote d-flex align-items-center justify-content-center">
                          <i className="icon-quote-left" />
                        </span>
                        <p>
                          Far far away, behind the word mountains, far from the
                          countries Vokalia and Consonantia, there live the
                          blind texts.
                        </p>
                        <p className="name">Mark Huff</p>
                        <span className="position">Businesswoman</span>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="testimony-wrap d-flex">
                      <div
                        className="user-img"
                        style={{ backgroundImage: "url(images/person_4.jpg)" }}
                      />
                      <div className="text pl-4">
                        <span className="quote d-flex align-items-center justify-content-center">
                          <i className="icon-quote-left" />
                        </span>
                        <p>
                          Far far away, behind the word mountains, far from the
                          countries Vokalia and Consonantia, there live the
                          blind texts.
                        </p>
                        <p className="name">Rodel Golez</p>
                        <span className="position">Businesswoman</span>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="testimony-wrap d-flex">
                      <div
                        className="user-img"
                        style={{ backgroundImage: "url(images/person_1.jpg)" }}
                      />
                      <div className="text pl-4">
                        <span className="quote d-flex align-items-center justify-content-center">
                          <i className="icon-quote-left" />
                        </span>
                        <p>
                          Far far away, behind the word mountains, far from the
                          countries Vokalia and Consonantia, there live the
                          blind texts.
                        </p>
                        <p className="name">Ken Bosh</p>
                        <span className="position">Businesswoman</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </>
    );
  };
}

export default About;
