import React from "react";
import { isMobile } from "react-device-detect";

class OurTeam extends React.Component {
  state = { displayAI: "none", displayCyber: "none" };
  handleReadmoreAI = () => {
    this.setState({ displayAI: "block" });
  };
  handleReadmoreCyber = () => {
    this.setState({ displayCyber: "block" });
  };
  componentDidMount() {
    var jsElm = document.createElement("script");
    jsElm.src = "js/main.js";
    document.body.appendChild(jsElm);
  }
  render = () => {
    return (
      <>
        <section
          className="hero-wrap hero-wrap-2"
          style={{ backgroundImage: 'url("images/team.jpg")' }}
        >
          <div className="overlay" />
          <div className="container">
            <div className="row no-gutters slider-text align-items-center justify-content-center">
              <div className="col-md-9 ftco-animate text-center">
                <h1 className="mb-2 bread">Our Team</h1>
                <p className="breadcrumbs">
                  <span className="mr-2">
                    <a href="/home">
                      Home <i className="ion-ios-arrow-forward" />
                    </a>
                  </span>
                  <span>
                    Our Team <i className="ion-ios-arrow-forward" />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="ftco-section">
          <div className="container">
            <div className="row justify-content-center mb-5 pb-2 p-4">
              <div className="col-md-8 text-center heading-section ftco-animate ">
                <h2 className="mb-4">Get to Know the Penguins!</h2>
              </div>
              <p>
                Our team is like a family, and for 5 years we have been growing
                organically to meet the needs of our customers. We currently
                have a team of nine full-time employees who each play an
                integral role in the company. We have dedicated full-time
                positions to project management, customer service, website
                development, and digital marketing to ensure we can provide you
                with fast, responsive service.
              </p>
              <p>
                At Quick Serve IT Solutions, we love what we do, and try to have
                a little bit of fun while doing it. We are in the office during
                business hours every day– our team would love to meet you!
              </p>
            </div>
            {/* <div className="row no-gutters "> */}
            {/* <div className="col-lg-2 col-md-6 col-sm-12">
                <div
                  className="services-2 noborder-left text-center ftco-animate"
                  style={{
                    border: "2px solid #fbfbff",
                    margin: 4,
                    padding: 10,
                  }}
                >
                  <div className="icon  d-flex justify-content-center align-items-center">
                    <img
                      src="img/team/modal3.jpeg"
                      alt="img"
                      style={{
                        width: "-webkit-fill-available",
                        
                      }}
                    />
                  </div>
                  <h5>Rahul Prasad</h5>
                  <span>Founder</span>
                  <div className="social">
                    <a
                      href="#1"
                      className="icon p-1 justify-content-center align-items-center"
                    >
                      <i className="icon-twitter" />
                    </a>
                    <a
                      href="#1"
                      className="icon p-1 justify-content-center align-items-center"
                    >
                      <i className="icon-facebook" />
                    </a>
                    <a
                      href="#1"
                      className="icon p-1 justify-content-center align-items-center"
                    >
                      <i className="icon-google-plus" />
                    </a>
                    <a
                      href="#1"
                      className="icon p-1 justify-content-center align-items-center"
                    >
                      <i className="icon-linkedin" />
                    </a>
                  </div>
                </div>
              </div> */}
            {/* <div className="col-lg-9 col-md-6 wrap-about pr-md-4 ftco-animate">
                <div
                  style={{
                    paddingTop: 10,
                    paddingLeft: 20,
                  }}
                >
                  <h5>
                    Mr. Rahul Prasad is an Indian business magnate, software
                    developer, investor, and philanthropist. He is best known as
                    the founder of QuickServe IT Solution
                  </h5>
                </div>
              </div> */}
            {/* </div> */}
            <div
              className="row no-gutters "
              style={
                isMobile
                  ? { borderRadius: 1 }
                  : {
                      display: "inline-flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }
              }
            >
              <div className="col-lg-2 col-md-6 col-sm-12">
                <div
                  className="services-2  text-center ftco-animate"
                  style={{
                    border: "1px solid white",
                    margin: 4,
                    padding: 10,
                  }}
                >
                  <div className="icon  d-flex justify-content-center align-items-center">
                    <img
                      src="img/team/modal3.jpeg"
                      alt="img"
                      style={{
                        width: "-webkit-fill-available",
                      }}
                    />
                  </div>
                  <h5>Rahul Prasad</h5>
                  <span>Founder</span>
                  <div className="social">
                    <a
                      href="#1"
                      className="icon p-1 justify-content-center align-items-center"
                    >
                      <i className="icon-twitter" />
                    </a>
                    <a
                      href="#1"
                      className="icon p-1 justify-content-center align-items-center"
                    >
                      <i className="icon-facebook" />
                    </a>
                    <a
                      href="#1"
                      className="icon p-1 justify-content-center align-items-center"
                    >
                      <i className="icon-google-plus" />
                    </a>
                    <a
                      href="#1"
                      className="icon p-1 justify-content-center align-items-center"
                    >
                      <i className="icon-linkedin" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12">
                <div
                  className="services-2 noborder-left text-center ftco-animate"
                  style={{
                    border: "1px solid white",
                    margin: 4,
                    padding: 10,
                  }}
                >
                  <div className="icon  d-flex justify-content-center align-items-center">
                    <img
                      src="img/team/modal2.jpeg"
                      alt="img"
                      style={{
                        width: "-webkit-fill-available",
                      }}
                    />
                  </div>
                  <h5>Ritesh Sharma</h5>
                  <span>Cofounder</span>
                  <div className="social">
                    <a
                      href="#1"
                      className="icon p-1 justify-content-center align-items-center"
                    >
                      <i className="icon-twitter" />
                    </a>
                    <a
                      href="#1"
                      className="icon p-1 justify-content-center align-items-center"
                    >
                      <i className="icon-facebook" />
                    </a>
                    <a
                      href="#1"
                      className="icon p-1 justify-content-center align-items-center"
                    >
                      <i className="icon-google-plus" />
                    </a>
                    <a
                      href="#1"
                      className="icon p-1 justify-content-center align-items-center"
                    >
                      <i className="icon-linkedin" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12">
                <div
                  className="services-2 noborder-left text-center ftco-animate"
                  style={{
                    border: "1px solid white",
                    margin: 4,
                    padding: 10,
                  }}
                >
                  <div className="icon  d-flex justify-content-center align-items-center">
                    <img
                      src="img/team/modal.jpeg"
                      alt="img"
                      style={{
                        width: "-webkit-fill-available",
                      }}
                    />
                  </div>
                  <h5>Shravan Kashyap</h5>
                  <span>Chief Technical Officer</span>
                  <div className="social">
                    <a
                      href="#1"
                      className="icon p-1 justify-content-center align-items-center"
                    >
                      <i className="icon-twitter" />
                    </a>
                    <a
                      href="#1"
                      className="icon p-1 justify-content-center align-items-center"
                    >
                      <i className="icon-facebook" />
                    </a>
                    <a
                      href="#1"
                      className="icon p-1 justify-content-center align-items-center"
                    >
                      <i className="icon-google-plus" />
                    </a>
                    <a
                      href="#1"
                      className="icon p-1 justify-content-center align-items-center"
                    >
                      <i className="icon-linkedin" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 ">
                <div
                  className="services-2 noborder-left text-center ftco-animate"
                  style={{
                    border: "1px solid white",
                    margin: 4,
                    padding: 10,
                  }}
                >
                  <div className="icon  d-flex justify-content-center align-items-center">
                    <img
                      src="img/team/modal4.jpeg"
                      alt="img"
                      style={{
                        width: "-webkit-fill-available",
                      }}
                    />
                  </div>
                  <h5>Dev Ashish Sahu</h5>
                  <span>Chief Executive Officer</span>
                  <div className="social">
                    <a
                      href="#1"
                      className="icon p-1 justify-content-center align-items-center"
                    >
                      <i className="icon-twitter" />
                    </a>
                    <a
                      href="#1"
                      className="icon p-1 justify-content-center align-items-center"
                    >
                      <i className="icon-facebook" />
                    </a>
                    <a
                      href="#1"
                      className="icon p-1 justify-content-center align-items-center"
                    >
                      <i className="icon-google-plus" />
                    </a>
                    <a
                      href="#1"
                      className="icon p-1 justify-content-center align-items-center"
                    >
                      <i className="icon-linkedin" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12">
                <div
                  className="services-2 noborder-left text-center ftco-animate"
                  style={{
                    border: "1px solid white",
                    margin: 4,
                    padding: 10,
                  }}
                >
                  <div className="icon  d-flex justify-content-center align-items-center">
                    <img
                      src="img/team/modal1.jpeg"
                      alt="img"
                      style={{ width: "-webkit-fill-available" }}
                    />
                  </div>
                  <h5>Lokendra Kumar</h5>
                  <span>Tech Lead</span>
                  <div className="social">
                    <a
                      href="#1"
                      className="icon p-1 justify-content-center align-items-center"
                    >
                      <i className="icon-twitter" />
                    </a>
                    <a
                      href="#1"
                      className="icon p-1 justify-content-center align-items-center"
                    >
                      <i className="icon-facebook" />
                    </a>
                    <a
                      href="#1"
                      className="icon p-1 justify-content-center align-items-center"
                    >
                      <i className="icon-google-plus" />
                    </a>
                    <a
                      href="#1"
                      className="icon p-1 justify-content-center align-items-center"
                    >
                      <i className="icon-linkedin" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };
}
export default OurTeam;
