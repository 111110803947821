import React from "react";
import { isMobile } from "react-device-detect";
import { withRouter } from "react-router-dom";
function Footer(props) {
  return (
    <footer
      className="ftco-footer ftco-bg-dark ftco-section"
      style={{ paddingTop: 30, paddingBottom: 1 }}
    >
      <div className="container">
        <div className="row mb-2">
          <div className="col-md-6 col-sm-12 col-lg-3">
            <div className="ftco-footer-widget mb-2">
              <h2 className="ftco-heading-2 mb-2">Contact Us</h2>
              <div className="block-23 mb-1">
                <ul style={{ marginBottom: 1 }}>
                  <li>
                    <span className="icon icon-map-marker" />
                    <span className="text">
                      Plot no 176, Aliganj Mahanagar Gomti Nagar, Lucknow, Uttar
                      Pradesh 226006
                    </span>
                  </li>
                  <li>
                    <a href="/home">
                      <span className="icon icon-phone" />
                      <span className="text">{window.phone}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className="col-md-6 col-sm-12  col-lg-3"
            style={{ paddingTop: isMobile ? "" : 35 }}
          >
            <div className="ftco-footer-widget mb-5">
              <div className="block-23 mb-3">
                <ul>
                  <li>
                    <span className="icon icon-map-marker" />
                    <span className="text">
                      Budha talab, opposite sitala mandir, kankali para, Raipur,
                      492001, Chhattisgarh
                    </span>
                  </li>

                  <li>
                    <a href="/home">
                      <span className="icon icon-envelope" />
                      <span className="text">{window.mail}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12  col-lg-3">
            <div className="ftco-footer-widget mb-2 ml-md-2">
              <h2 className="ftco-heading-2 mb-2">Important Links</h2>
              <ul className="list-unstyled">
                <li
                  onClick={(e) => {
                    e.preventDefault();
                    props.history.push("/home");
                  }}
                  style={{ cursor: "pointer", color: "aliceblue" }}
                >
                  <a>
                    <span className="ion-ios-arrow-round-forward mr-2" />
                    Home
                  </a>
                </li>
                <li
                  onClick={(e) => {
                    e.preventDefault();
                    props.history.push("/about");
                  }}
                  style={{ cursor: "pointer", color: "aliceblue" }}
                >
                  <a>
                    <span className="ion-ios-arrow-round-forward mr-2" />
                    About
                  </a>
                </li>
                <li
                  onClick={(e) => {
                    e.preventDefault();
                    props.history.push("/services");
                  }}
                  style={{ cursor: "pointer", color: "aliceblue" }}
                >
                  <a>
                    <span className="ion-ios-arrow-round-forward mr-2" />
                    Services
                  </a>
                </li>
                <li
                  onClick={(e) => {
                    e.preventDefault();
                    props.history.push("/projects");
                  }}
                  style={{ cursor: "pointer", color: "aliceblue" }}
                >
                  <a>
                    <span className="ion-ios-arrow-round-forward mr-2" />
                    Projects
                  </a>
                </li>
                <li
                  onClick={(e) => {
                    e.preventDefault();
                    props.history.push("/contact");
                  }}
                  style={{ cursor: "pointer", color: "aliceblue" }}
                >
                  <a>
                    <span className="ion-ios-arrow-round-forward mr-2" />
                    Contact
                  </a>
                </li>
                <li
                  onClick={(e) => {
                    e.preventDefault();
                    props.history.push("/team");
                  }}
                  style={{ cursor: "pointer", color: "aliceblue" }}
                >
                  <a>
                    <span className="ion-ios-arrow-round-forward mr-2" />
                    Team
                  </a>
                </li>
                <li
                  onClick={(e) => {
                    e.preventDefault();
                    props.history.push("/career");
                  }}
                  style={{ cursor: "pointer", color: "aliceblue" }}
                >
                  <span>
                    <span className="ion-ios-arrow-round-forward mr-2" />
                    Career
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-md-12 text-center">
            <p>
              Copyright © All rights reserved |
              <span>{` ${window.companyName}`}</span>
            </p>
          </div>
        </div> */}
      </div>
    </footer>
  );
}

export default withRouter(Footer);
