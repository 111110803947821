import React from "react";
import db from "../../hoc/Firebase";
import Address from "../Address/Address";
import ContactUs from "./Contact_us";
const initState = {
  name: "",
  email: "",
  message: "",
  subject: "",
};
class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initState,
    };
  }
  handleAddContactUs = () => {
    const metaInfo = {
      platform: navigator.platform,
      connection: navigator.connection
        ? navigator.connection.effectiveType
          ? navigator.connection.effectiveType
          : navigator.connection.type
          ? navigator.connection.type
          : null
        : null,
      maxTouchPoints: navigator.maxTouchPoints,
      userAgent: navigator.userAgent,
    };

    db.collection("contactUs")
      .add({ ...this.state, metaInfo, createdAt: new Date() })
      .then(() => this.state.firstName && this.rollbackStates())
      .catch(() => this.state.firstName && this.rollbackStates());
  };
  rollbackStates = () => {
    this.setState(initState);
  };
  componentDidMount() {
    var jsElm = document.createElement("script");
    jsElm.src = "js/main.js";
    document.body.appendChild(jsElm);
  }
  render = () => {
    return (
      <>
        <section
          className="hero-wrap hero-wrap-2"
          style={{ backgroundImage: 'url("images/contcat1.jpg")' }}
        >
          <div className="overlay" />
          <div className="container">
            <div className="row no-gutters slider-text align-items-center justify-content-center">
              <div className="col-md-9 ftco-animate text-center">
                <h1 className="mb-2 bread">Contact Us</h1>
                <p className="breadcrumbs">
                  <span className="mr-2">
                    <a href="index.html">
                      Home <i className="ion-ios-arrow-forward" />
                    </a>
                  </span>
                  <span>
                    Contact <i className="ion-ios-arrow-forward" />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="ftco-section">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="item">
                  <div className="testimony-wrap d-flex">
                    <div className="text pl-4">
                      <span className="quote d-flex align-items-center justify-content-center">
                        <i className="icon-quote-left" />
                      </span>
                      <div className="icon">
                        <span className="icon-map-o" />
                      </div>
                      <span>
                        <p className="description">
                          <span>Address:</span> {window.address}
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="item">
                  <div className="testimony-wrap d-flex">
                    <div className="text pl-4">
                      <span className="quote d-flex align-items-center justify-content-center">
                        <i className="icon-quote-left" />
                      </span>
                      <div className="icon">
                        <span className="icon-mobile-phone" />
                      </div>
                      <p>
                        <span>Phone:</span>
                        <a href={`tel:${window.raipurPhone}`}>
                          {window.raipurPhone}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="item">
                  <div className="testimony-wrap d-flex">
                    <div className="text pl-4">
                      <span className="quote d-flex align-items-center justify-content-center">
                        <i className="icon-quote-left" />
                      </span>
                      <div className="icon">
                        <span className="icon-envelope-o" />
                      </div>
                      <p>
                        <span>Email:</span>
                        <a href={`mailto:${window.mail}}`}>{window.mail}</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section
          className="ftco-section contact-section"
          style={{ paddingTop: "2rem" }}
        >
          <div className="container">
            <div className="row block-9 justify-content-center mb-5">
              <div className="col-md-12 ">
                <h2 className="text-center">
                  If you got any questions please do not hesitate to send us a
                  message
                </h2>
              </div>
            </div>
            <div className="row block-9 justify-content-center mb-5">
              <div className="col-lg-4 col-md-4">
                <div className="contact-about">
                  <h3>Quickserve IT Solution</h3>
                  <iframe
                    title="map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3718.884666510655!2d81.62630321445806!3d21.236421586033543!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a28ddb9269f39dd%3A0x6fdb95cf3195bc6c!2sKankalipara%2C%20Raipur%2C%20Chhattisgarh%20492001!5e0!3m2!1sen!2sin!4v1588433135832!5m2!1sen!2sin"
                    style={{
                      border: 0,
                      width: "100%",
                      height: "250px",
                    }}
                    allowFullScreen={false}
                  />
                </div>
              </div>
              <Address />
              <ContactUs />
            </div>
            {/* <div className="row block-9 justify-content-center mb-5">
              <div className="col-md-4 ">
                <h3>Map</h3>
                <div>
                  <iframe
                    title="map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3718.884666510655!2d81.62630321445806!3d21.236421586033543!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a28ddb9269f39dd%3A0x6fdb95cf3195bc6c!2sKankalipara%2C%20Raipur%2C%20Chhattisgarh%20492001!5e0!3m2!1sen!2sin!4v1588433135832!5m2!1sen!2sin"
                    style={{
                      border: 0,
                      width: "100%",
                      height: "480px",
                      paddingTop: 30,
                    }}
                    allowFullScreen={false}
                  />
                </div>
              </div>
              <div className="col-md-4 "></div>
              <div className="col-md-4 ">
                <h2>Questions</h2>
                <form action="#" className="bg-light p-5 contact-form">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your Name"
                      value={this.state.name}
                      onChange={(e) => {
                        e.preventDefault();
                        this.setState({ name: e.target.value });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your Email"
                      value={this.state.email}
                      onChange={(e) => {
                        e.preventDefault();
                        this.setState({ email: e.target.value });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Subject"
                      value={this.state.subject}
                      onChange={(e) => {
                        e.preventDefault();
                        this.setState({ subject: e.target.value });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      name
                      id
                      cols={30}
                      rows={7}
                      placeholder="Message"
                      className="form-control"
                      value={this.state.message}
                      onChange={(e) => {
                        e.preventDefault();
                        this.setState({ message: e.target.value });
                      }}
                      defaultValue={""}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleAddContactUs();
                      }}
                      defaultValue="Send Message"
                      className="btn btn-primary py-2 px-3"
                    />
                  </div>
                </form>
              </div>
            </div> */}
          </div>
        </section>
        {/* <section className="ftco-section ftco-no-pb ftco-no-pt">
          <div className="container-fluid px-0">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div id="map" className="bg-white" />
              </div>
            </div>
          </div>
        </section> */}
      </>
    );
  };
}

export default Contact;
