import React from "react";
import db from "../../hoc/Firebase";
import { withRouter } from "react-router-dom";
import { isMobile } from "react-device-detect";
const initState = {
  displayAI: "none",
  displayCyber: "none",
  firstName: "",
  lastName: "",
  message: "",
  phoneNumber: "",
};
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initState,
    };
  }

  handleReadmoreAI = () => {
    this.setState({ displayAI: "block" });
  };
  handleReadmoreCyber = () => {
    this.setState({ displayCyber: "block" });
  };
  componentDidMount() {
    var jsElm = document.createElement("script");
    jsElm.src = "js/main.js";
    document.body.appendChild(jsElm);
  }
  handleAddContactUs = () => {
    const metaInfo = {
      platform: navigator.platform,
      connection: navigator.connection
        ? navigator.connection.effectiveType
          ? navigator.connection.effectiveType
          : navigator.connection.type
          ? navigator.connection.type
          : null
        : null,
      maxTouchPoints: navigator.maxTouchPoints,
      userAgent: navigator.userAgent,
    };

    db.collection("contactUs")
      .add({ ...this.state, metaInfo, createdAt: new Date() })
      .then(() => this.state.firstName && this.rollbackStates())
      .catch(() => this.state.firstName && this.rollbackStates());
  };
  rollbackStates = () => {
    this.setState(initState);
  };
  render = () => {
    console.log(this.props);
    return (
      <>
        <section className="home-slider owl-carousel">
          <div
            className="slider-item"
            style={{ backgroundImage: "url(images/bg_1.jpg)" }}
          >
            <div className="overlay" />
            <div className="container">
              <div
                className="row no-gutters slider-text align-items-center justify-content-start"
                data-scrollax-parent="true"
              >
                <div className="col-md-7 ftco-animate">
                  <span className="subheading">
                    Welcome to quickserve it solution
                  </span>
                  <h1 className="mb-4">We Are The Best Techical Team</h1>
                  <p>
                    <a href="/home" className="btn btn-primary px-4 py-3 mt-3">
                      Our Services
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="slider-item"
            style={{ backgroundImage: "url(images/bg_2.jpg)" }}
          >
            <div className="overlay" />
            <div className="container">
              <div
                className="row no-gutters slider-text align-items-center justify-content-start"
                data-scrollax-parent="true"
              >
                <div className="col-md-7 ftco-animate">
                  <span className="subheading">
                    Todays Talent, Tommorow Success
                  </span>
                  <h1 className="mb-4">We Help to Grow Your Business</h1>
                  <p>
                    <button
                      className="btn btn-primary py-3 px-4"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push("/services");
                      }}
                    >
                      Our Services
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="slider-item"
            style={{ backgroundImage: "url(img/header_bg.webp)" }}
          >
            <div className="overlay" />
            <div className="container">
              <div
                className="row no-gutters slider-text align-items-center justify-content-start"
                data-scrollax-parent="true"
              >
                <div className="col-md-7 ftco-animate">
                  <span className="subheading">
                    Welcome to quickserve it solution
                  </span>
                  <h1 className="mb-5">
                    We are team of developers making application software with
                    different technologies
                  </h1>
                  <p>
                    <button
                      className="btn btn-primary py-3 px-4"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push("/services");
                      }}
                    >
                      Our Services
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="ftco-section">
          <div className="container">
            <div className="row d-flex">
              <div className="col-md-5 order-md-last wrap-about align-items-stretch">
                <div className="wrap-about-border ftco-animate">
                  <div
                    className="img"
                    style={{ backgroundImage: "url(images/about.jpg)" }}
                  />
                  <div className="text">
                    <h3>Our products are good, our service is better. </h3>
                    <p>
                      1. Web Designing.
                      <br /> 2. Web Development. <br /> 3. Android app
                      development. <br /> 4. IOS development. <br /> 5. Hybrid
                      applications. <br /> 6. Consulting.
                    </p>

                    <p>
                      <button
                        className="btn btn-primary py-3 px-4"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.push("/contact");
                        }}
                      >
                        Contact us
                      </button>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-7 wrap-about pr-md-4 ftco-animate">
                <h2 className="mb-4">Our Main Features</h2>

                <div className="row mt-5">
                  <div className="col-lg-6">
                    <div className="services active text-center">
                      <div className="icon mt-2 d-flex justify-content-center align-items-center">
                        <span className="flaticon-collaboration" />
                      </div>
                      <div className="text media-body">
                        <h3>Organization</h3>
                        <p>
                          Identifying the resources and dividing the modules
                          into small chunks. To inculcate a common objective.
                        </p>
                      </div>
                    </div>
                    <div className="services text-center">
                      <div className="icon mt-2 d-flex justify-content-center align-items-center">
                        <span className="flaticon-analysis" />
                      </div>
                      <div className="text media-body">
                        <h3>Risk Analysis</h3>
                        <p>
                          We always ensure that we take only calculated risk. As
                          you start implementing new ideas.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="services text-center">
                      <div className="icon mt-2 d-flex justify-content-center align-items-center">
                        <span className="flaticon-search-engine" />
                      </div>
                      <div className="text media-body">
                        <h3>Marketing Strategy</h3>
                        <p>
                          Planning ahead to make most out of anything is really
                          essential. Hence, We plan, we execute, we deliver.
                        </p>
                      </div>
                    </div>
                    <div className="services text-center">
                      <div className="icon mt-2 d-flex justify-content-center align-items-center">
                        <span className="flaticon-handshake" />
                      </div>
                      <div className="text media-body">
                        <h3>Capital Market</h3>
                        <p>
                          It's always high time on stocks and requires
                          experience for when it's time to do the investments.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section
          className="ftco-intro ftco-no-pb img"
          style={{ backgroundImage: "url(images/bg_3.jpg)" }}
        >
          <div className="container">
            <div className="row justify-content-center mb-5">
              <div className="col-md-10 text-center heading-section heading-section-white ftco-animate">
                <h2 className="mb-0">You Always Get the Best Guidance</h2>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="ftco-counter" id="section-counter">
          <div className="container">
            <div className="row d-md-flex align-items-center justify-content-center">
              <div className="wrapper">
                <div className="row d-md-flex align-items-center">
                  <div className="col-md d-flex justify-content-center counter-wrap ftco-animate">
                    <div className="block-18">
                      <div className="icon">
                        <span className="flaticon-doctor" />
                      </div>
                      <div className="text">
                        <strong className="number" data-number={10}>
                          0
                        </strong>
                        <span>Projects Completed</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md d-flex justify-content-center counter-wrap ftco-animate">
                    <div className="block-18">
                      <div className="icon">
                        <span className="flaticon-doctor" />
                      </div>
                      <div className="text">
                        <strong className="number" data-number={10}>
                          0
                        </strong>
                        <span>Satisfied Customer</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md d-flex justify-content-center counter-wrap ftco-animate">
                    <div className="block-18">
                      <div className="icon">
                        <span className="flaticon-doctor" />
                      </div>
                      <div className="text">
                        <strong className="number" data-number={2}>
                          0
                        </strong>
                        <span>Awwards Received</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md d-flex justify-content-center counter-wrap ftco-animate">
                    <div className="block-18">
                      <div className="icon">
                        <span className="flaticon-doctor" />
                      </div>
                      <div className="text">
                        <strong className="number" data-number={5}>
                          0
                        </strong>
                        <span>Years of Experienced</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="ftco-section" style={{ paddingBottom: "4rem" }}> */}
        <section className="ftco-section" style={{ paddingTop: 10 }}>
          <div className="container">
            <div className="row justify-content-center mb-5 pb-2">
              <div className="col-md-8 text-center heading-section ftco-animate">
                <h2 className="mb-4">Our Best Services</h2>
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col-lg-4 d-flex">
                <div className="services-2 noborder-left text-center ftco-animate">
                  <div className="icon mt-2 d-flex justify-content-center align-items-center">
                    <span className="flaticon-analysis" />
                  </div>
                  <div className="text media-body">
                    <h3>Customer Relationship Management</h3>
                    <p>
                      Creating new customers and maintaining current is the main
                      focus of every business, for this is stability and growth.
                    </p>
                    <button
                      className="btn btn-primary buttonHover"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleReadmoreAI();
                      }}
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="services-2 text-center ftco-animate">
                  <div className="icon mt-2 d-flex justify-content-center align-items-center">
                    <span className="flaticon-business" />
                  </div>
                  <div className="text media-body">
                    <h3>Application & Software Development</h3>
                    <p>
                      We are interested in developing your business through a
                      professional application/software development process.
                    </p>
                    <button
                      className="btn btn-primary buttonHover"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleReadmoreAI();
                      }}
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="services-2 text-center ftco-animate">
                  <div className="icon mt-2 d-flex justify-content-center align-items-center">
                    <span className="flaticon-insurance" />
                  </div>
                  <div className="text media-body">
                    <h3>Enterprise Resource & Planning</h3>
                    <p>
                      We specialize in the design, development and
                      implementation of intelligent customized enterprise
                      solutions.
                      <br />
                      <br />
                    </p>
                    <button
                      className="btn btn-primary buttonHover"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleReadmoreAI();
                      }}
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="services-2 noborder-left noborder-bottom text-center ftco-animate">
                  <div className="icon mt-2 d-flex justify-content-center align-items-center">
                    <span className="flaticon-money" />
                  </div>
                  <div className="text media-body">
                    <h3>Search Engine Optimization (SEO)</h3>
                    <p>
                      We have an excellent team of search engine optimizers and
                      web masters who are dedicated on SEO projects and Internet
                      marketing.
                    </p>
                    <button
                      className="btn btn-primary buttonHover"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleReadmoreAI();
                      }}
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="services-2 text-center noborder-bottom ftco-animate">
                  <div
                    className="icon mt-2 d-flex justify-content-center align-items-center"
                    style={{ padding: "inherit" }}
                  >
                    <img
                      src="images/Ailogo.jpg"
                      alt="img"
                      style={{ width: 50, height: 50 }}
                    />
                  </div>
                  <div className="text media-body">
                    <h3>Artificial Intelligence (AI)</h3>
                    <p>
                      We create powerful and intelligent solutions that are
                      infused with high-end Artificial Intelligence development
                      services that not only integrate seamlessly with your
                      business processes but are self-aware of
                    </p>
                    <p style={{ display: this.state.displayAI }}>
                      boosting operational efficiency and business growth.
                    </p>
                    <button
                      className="btn btn-primary buttonHover"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleReadmoreAI();
                      }}
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="services-2 text-center noborder-bottom ftco-animate">
                  <div
                    className="icon mt-2 d-flex justify-content-center align-items-center"
                    style={{ padding: "inherit" }}
                  >
                    <img
                      src="images/cyberLogo.png"
                      alt="img"
                      style={{ width: 50, height: 50 }}
                    />
                  </div>
                  <div className="text media-body">
                    <h3>Cyber Security</h3>
                    <p className="wow fadeInRight" data-wow-duration="0.5s">
                      Different size organizations cope with different problems,
                      but all have employees as the weak link in their IT
                      security. The challenges of creating and running an
                      awareness program vary depending on the
                    </p>
                    <p style={{ display: this.state.displayCyber }}>
                      amount of employees. Select from the varied available
                      options that we will suggest best practices for your
                      size/type of organization.
                    </p>
                    <button
                      className="btn btn-primary buttonHover1 "
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleReadmoreCyber();
                      }}
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section
          className="ftco-intro ftco-no-pb img"
          style={{ backgroundImage: "url(images/bg_1.jpg)" }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9 col-md-8 d-flex align-items-center heading-section heading-section-white ftco-animate">
                <h2 className="mb-3 mb-md-0">
                  You Always Get the Best Guidance
                </h2>
              </div>
              <div className="col-lg-3 col-md-4 ftco-animate">
                <p className="mb-0">
                  <a href="/home" className="btn btn-white py-3 px-4">
                    Request Quote
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section> */}
        <section
          className="ftco-section ftco-no-pb"
          style={{ paddingTop: "4rem" }}
        >
          <div className="container-fluid px-0">
            <div className="row no-gutters justify-content-center mb-5">
              <div className="col-md-7 text-center heading-section ftco-animate">
                <h2 className="mb-4">Our Recent Projects</h2>
              </div>
            </div>
            <div
              className="row ftco-animate justify-content-center"
              style={{ width: "inherit" }}
            >
              <div className="col-md-12" style={{ marginLeft: 30 }}>
                <div className="carousel-testimony owl-carousel">
                  <div
                    className="item"
                    style={isMobile ? { width: 350, margin: 0 } : { margin: 0 }}
                  >
                    <div
                      className="project img ftco-animate d-flex justify-content-center align-items-center"
                      style={{ backgroundImage: "url(images/books.png)" }}
                    >
                      <div
                        className={isMobile ? "overlayProject" : "overlay"}
                      />
                      {/* <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          top: 7,
                          left: 23,
                          position: "absolute",
                          paddingBottom: 10,
                        }}
                      >
                        <h3
                          style={{
                            fontSize: 20,
                            textAlign: "initial",
                            color: "white",
                          }}
                        >
                          Book Distribution System
                        </h3>
                      </div> */}
                      <a
                        href="/"
                        className={
                          isMobile
                            ? "btn-siteproject d-flex align-items-center justify-content-center"
                            : "btn-site d-flex align-items-center justify-content-center"
                        }
                      >
                        <span className="icon-subdirectory_arrow_right" />
                      </a>
                      <div className="text text-center p-4">
                        <h3
                          style={{
                            fontSize: 20,
                            textAlign: "initial",
                            color: "white",
                          }}
                        >
                          Book Distribution System
                        </h3>
                        <span style={{ fontSize: 12, textAlign: "initial" }}>
                          <p className="description">
                            <span>{`What does it do?`}</span>
                            <br />
                            <span>{`- Manages stock.`}</span>
                            <br />
                            <span>{`- Makes books ready to go, on prior request.`}</span>
                            <br />
                            <span>{`-Expense Detailing.`}</span>
                            <br />
                            <span>{`-Managing database of the partners around the market.`}</span>
                            &nbsp;
                          </p>
                        </span>
                      </div>
                    </div>
                    <div>
                      <h4> Book Distribution System</h4>
                    </div>
                  </div>
                  <div
                    className="item"
                    style={isMobile ? { width: 350, margin: 0 } : { margin: 0 }}
                  >
                    <div
                      className="project  img ftco-animate d-flex justify-content-center align-items-center"
                      style={{ backgroundImage: "url(images/tracking.png)" }}
                    >
                      <div
                        className={isMobile ? "overlayProject" : "overlay"}
                      />

                      <a
                        href="/"
                        className={
                          isMobile
                            ? "btn-siteproject d-flex align-items-center justify-content-center"
                            : "btn-site d-flex align-items-center justify-content-center"
                        }
                      >
                        <span className="icon-subdirectory_arrow_right" />
                      </a>
                      <div className="text text-center p-4">
                        <h3
                          style={{
                            fontSize: 20,
                            textAlign: "initial",
                            color: "white",
                          }}
                        >
                          Tracking application
                        </h3>
                        <span style={{ fontSize: 12, textAlign: "initial" }}>
                          <p className="description">
                            {`We have made a tracking application to track the associates end to end. Associates have to just start the application and application will make route of the same.So that the information provided by the associates can be cross checked and the owner can evaluate money for accountability.`}
                          </p>
                        </span>
                      </div>
                    </div>
                    <div>
                      <h4> Tracking application</h4>
                    </div>
                  </div>
                  <div
                    className="item"
                    style={isMobile ? { width: 350, margin: 0 } : { margin: 0 }}
                  >
                    <div
                      className="project  img ftco-animate d-flex justify-content-center align-items-center"
                      style={{ backgroundImage: "url(images/Tractor.png)" }}
                    >
                      <div
                        className={isMobile ? "overlayProject" : "overlay"}
                      />

                      <a
                        href="/"
                        className={
                          isMobile
                            ? "btn-siteproject d-flex align-items-center justify-content-center"
                            : "btn-site d-flex align-items-center justify-content-center"
                        }
                      >
                        <span className="icon-subdirectory_arrow_right" />
                      </a>
                      <div className="text text-center p-4">
                        <h3
                          style={{
                            fontSize: 20,
                            textAlign: "initial",
                            color: "white",
                          }}
                        >
                          Tractor App
                        </h3>
                        <span style={{ fontSize: 12, textAlign: "initial" }}>
                          <p className="description">
                            {`We have provided the basis of categorised clients with multiple stores and user(admin/ operator). Inventory management has been done with no hustle at all. And we are also showing reports at various level so that admin can plan his/her day ahead.`}
                          </p>
                        </span>
                      </div>
                    </div>
                    <div>
                      <h4> Tracking application</h4>
                    </div>
                  </div>
                  <div
                    className="item"
                    style={isMobile ? { width: 350, margin: 0 } : { margin: 0 }}
                  >
                    <div
                      className="project  img ftco-animate d-flex justify-content-center align-items-center"
                      style={{ backgroundImage: "url(images/loan.webp)" }}
                    >
                      <div
                        className={isMobile ? "overlayProject" : "overlay"}
                      />

                      <a
                        href="/"
                        className={
                          isMobile
                            ? "btn-siteproject d-flex align-items-center justify-content-center"
                            : "btn-site d-flex align-items-center justify-content-center"
                        }
                      >
                        <span className="icon-subdirectory_arrow_right" />
                      </a>
                      <div className="text text-center p-4">
                        <h3
                          className="description"
                          style={{
                            fontSize: 20,
                            textAlign: "initial",
                            color: "white",
                          }}
                        >
                          Loan by Jewellery Shop
                        </h3>
                        <span style={{ fontSize: 12, textAlign: "initial" }}>
                          <p className="description">
                            We have provided to client on taking the details and
                            performing calculations <br />
                            (Simple and Compound Interests) implicable to their
                            clients, with ease and make the things work
                            paperless.
                          </p>
                        </span>
                      </div>
                    </div>
                    <div>
                      <h4> Loan by Jewellery Shop</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="ftco-section ftco-no-pb">
          <div className="container">
            <div className="row no-gutters justify-content-center mb-1">
              <div className="col-md-7 text-center heading-section ftco-animate">
                <h2 className="mb-4">Testimonial</h2>
              </div>
            </div>
            <div className="row d-flex">
              {/* <div className="col-md-5 order-md-last wrap-about align-items-stretch">
                <div className="wrap-about-border">
                  <div
                    className="img"
                    style={{ height: "inherit", marginBottom: 0 }}
                  >
                    <img
                      src="images/newAi.png"
                      alt="img"
                      style={{ width: "inherit" }}
                    />
                  </div>
                </div>
              </div> */}
              <div className="col-md-12 ftco-animate">
                <p className="description">
                  We thanks our clients for counting on our excellence.
                  Appreciation stating confidence on ours efforts by your
                  clients is a thing to cherish. It gives us immense pleasure
                  and a possitive booster whenever our esteemed clients take
                  time to review us towards our deiliverables through
                  testimonials.
                </p>
                <div style={{ display: "flex" }}>
                  <span>1- </span>
                  <p className="description pl-2">
                    Right destination to fulfill all web requirements under one
                    roof is the only QSITS. Thank you to the team for delivering
                    all my given works in stipulated time. Good job done
                    <br />– Salchet Wilson. Canada
                  </p>
                </div>
                <div style={{ display: "flex" }}>
                  <span>2- </span>
                  <p className="description pl-2">
                    Quick serve IT solutions is absolutely a fantastic team of
                    professional developers and designers. We recently came
                    across to get our website designed and we are absolutely
                    happy with the way it turned out. The team is a mix of
                    experts in all functionalities.
                    <br />- Manish Wadhwa. Dubai. UAE
                  </p>
                </div>
                <div style={{ display: "flex" }}>
                  <span>3- </span>
                  <p className="description pl-2">
                    Second time I have dealt with them. Fast efficient service
                    with swift communication.
                    <br />- Daniel Crow. Australia
                  </p>
                </div>
                <div style={{ display: "flex" }}>
                  <span>4- </span>
                  <p className="description pl-2">
                    I was completely exhausted with the fake commitments
                    companies giving to me. I came across the team of Quick
                    Serve IT solutions last year and I must admit they are best
                    in their efforts. I would certainly recommend others to take
                    a moment discussing with them. Happy with their quality of
                    work with on time delivery.
                    <br />- Sanjeev Jain, India
                  </p>
                </div>
                <div style={{ display: "flex" }}>
                  <span>5- </span>

                  <p className="description pl-2">
                    Very professional and easy to handle and a super skilled
                    team. Choosing you all was certainly a good decision of
                    mine.
                    <br />- Sandeep Maheswari, India.
                  </p>
                </div>
                <div style={{ display: "flex" }}>
                  <span>6- </span>
                  <p className="description pl-2">
                    Very much satisfied with their qualitative services. I have
                    been in their contact with three of my most important
                    projects. They are really outstanding in their deliverables.
                    <br />- Mominh Alam, India
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section
          className="ftco-section ftco-consult ftco-no-pt ftco-no-pb"
          style={{ backgroundImage: "url(images/bg_5.jpg)" }}
          data-stellar-background-ratio="0.5"
        >
          <div className="overlay" />
          <div className="container">
            <div className="row justify-content-end">
              <div className="col-md-6 py-5 px-md-5">
                <div className="py-md-5">
                  <div className="heading-section heading-section-white ftco-animate mb-5">
                    <h2 className="mb-4">Request A Quote</h2>
                    <p>
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia, there live the blind
                      texts.
                    </p>
                  </div>
                  <form action="#" className="appointment-form ftco-animate">
                    <div className="d-md-flex">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          value={this.state.firstName}
                          onChange={(e) => {
                            e.preventDefault();
                            this.setState({ firstName: e.target.value });
                          }}
                        />
                      </div>
                      <div className="form-group ml-md-4">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          value={this.state.lastName}
                          onChange={(e) => {
                            e.preventDefault();
                            this.setState({ lastName: e.target.value });
                          }}
                        />
                      </div>
                    </div>
                    <div className="d-md-flex">
                      <div className="form-group ml-md-4">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Phone"
                          value={this.state.phoneNumber}
                          onChange={(e) => {
                            e.preventDefault();
                            this.setState({ phoneNumber: e.target.value });
                          }}
                        />
                      </div>
                    </div>
                    <div className="d-md-flex">
                      <div className="form-group">
                        <textarea
                          name
                          id
                          cols={30}
                          rows={2}
                          onChange={(e) => {
                            e.preventDefault();
                            this.setState({ message: e.target.value });
                          }}
                          value={this.state.message}
                          className="form-control"
                          placeholder="Message"
                          defaultValue={""}
                        />
                      </div>
                      <div className="form-group ml-md-4">
                        <input
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleAddContactUs();
                          }}
                          defaultValue="Request A Quote"
                          className="btn btn-white py-3 px-4"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="ftco-section bg-light">
        <div className="container">
          <div className="row justify-content-center mb-5 pb-2">
            <div className="col-md-8 text-center heading-section ftco-animate">
              <h2 className="mb-4">
                <span>Recent</span> Blog
              </h2>
              <p>
                Separated they live in. A small river named Duden flows by their
                place and supplies it with the necessary regelialia. It is a
                paradisematic country
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4 ftco-animate">
              <div className="blog-entry">
                <a
                  href="blog-single.html"
                  className="block-20 d-flex align-items-end"
                  style={{ backgroundImage: 'url("images/image_1.jpg")' }}
                >
                  <div className="meta-date text-center p-2">
                    <span className="day">26</span>
                    <span className="mos">June</span>
                    <span className="yr">2019</span>
                  </div>
                </a>
                <div className="text bg-white p-4">
                  <h3 className="heading">
                    <a href="/home">
                      Finance And Legal Working Streams Occur Throughout
                    </a>
                  </h3>
                  <p>
                    Far far away, behind the word mountains, far from the
                    countries Vokalia and Consonantia, there live the blind
                    texts.
                  </p>
                  <div className="d-flex align-items-center mt-4">
                    <p className="mb-0">
                      <a href="/home" className="btn btn-primary">
                        Read More
                        <span className="ion-ios-arrow-round-forward" />
                      </a>
                    </p>
                    <p className="ml-auto mb-0">
                      <a href="/home" className="mr-2">
                        Admin
                      </a>
                      <a href="/home" className="meta-chat">
                        <span className="icon-chat" /> 3
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 ftco-animate">
              <div className="blog-entry">
                <a
                  href="blog-single.html"
                  className="block-20 d-flex align-items-end"
                  style={{ backgroundImage: 'url("images/image_2.jpg")' }}
                >
                  <div className="meta-date text-center p-2">
                    <span className="day">26</span>
                    <span className="mos">June</span>
                    <span className="yr">2019</span>
                  </div>
                </a>
                <div className="text bg-white p-4">
                  <h3 className="heading">
                    <a href="/home">
                      Finance And Legal Working Streams Occur Throughout
                    </a>
                  </h3>
                  <p>
                    Far far away, behind the word mountains, far from the
                    countries Vokalia and Consonantia, there live the blind
                    texts.
                  </p>
                  <div className="d-flex align-items-center mt-4">
                    <p className="mb-0">
                      <a href="/home" className="btn btn-primary">
                        Read More
                        <span className="ion-ios-arrow-round-forward" />
                      </a>
                    </p>
                    <p className="ml-auto mb-0">
                      <a href="/home" className="mr-2">
                        Admin
                      </a>
                      <a href="/home" className="meta-chat">
                        <span className="icon-chat" /> 3
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 ftco-animate">
              <div className="blog-entry">
                <a
                  href="blog-single.html"
                  className="block-20 d-flex align-items-end"
                  style={{ backgroundImage: 'url("images/image_3.jpg")' }}
                >
                  <div className="meta-date text-center p-2">
                    <span className="day">26</span>
                    <span className="mos">June</span>
                    <span className="yr">2019</span>
                  </div>
                </a>
                <div className="text bg-white p-4">
                  <h3 className="heading">
                    <a href="/home">
                      Finance And Legal Working Streams Occur Throughout
                    </a>
                  </h3>
                  <p>
                    Far far away, behind the word mountains, far from the
                    countries Vokalia and Consonantia, there live the blind
                    texts.
                  </p>
                  <div className="d-flex align-items-center mt-4">
                    <p className="mb-0">
                      <a href="/home" className="btn btn-primary">
                        Read More
                        <span className="ion-ios-arrow-round-forward" />
                      </a>
                    </p>
                    <p className="ml-auto mb-0">
                      <a href="/home" className="mr-2">
                        Admin
                      </a>
                      <a href="/home" className="meta-chat">
                        <span className="icon-chat" /> 3
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
        <section className="ftco-section testimony-section">
          <div className="container">
            <div className="row justify-content-center mb-5">
              <div className="col-md-8 text-center heading-section ftco-animate">
                <h2 className="mb-4">Our Clients </h2>
              </div>
            </div>
            <div className="row ftco-animate justify-content-center">
              <div className="col-md-12">
                <div className="carousel-testimony owl-carousel">
                  <div className="item">
                    <div
                      className="testimony-wrap d-flex"
                      style={{ height: 200 }}
                    >
                      <img src="images/cleartax.png" alt="img"></img>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="testimony-wrap d-flex"
                      style={{ height: 200 }}
                    >
                      <img src="images/sesamStreet.jpg" alt="img"></img>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="testimony-wrap d-flex"
                      style={{ height: 200 }}
                    >
                      <img src="images/Phonix.png" alt="img"></img>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="testimony-wrap d-flex"
                      style={{ height: 200 }}
                    >
                      <img src="images/Societe.png" alt="img"></img>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="testimony-wrap d-flex"
                      style={{ height: 200 }}
                    >
                      <img src="images/SAP_logo.png" alt="img"></img>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="testimony-wrap d-flex"
                      style={{ height: 200 }}
                    >
                      <img src="images/Marks.png" alt="img"></img>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="testimony-wrap d-flex"
                      style={{ height: 200 }}
                    >
                      <img src="images/plains.png" alt="img"></img>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="testimony-wrap d-flex"
                      style={{ height: 200 }}
                    >
                      <img src="images/Standard.png" alt="img"></img>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="testimony-wrap d-flex"
                      style={{ height: 200 }}
                    >
                      <img src="images/commonFloor.png" alt="img"></img>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="testimony-wrap d-flex"
                      style={{ height: 200 }}
                    >
                      <img src="images/Sansung.png" alt="img"></img>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="testimony-wrap d-flex"
                      style={{ height: 200 }}
                    >
                      <img src="images/siemens.jpg" alt="img"></img>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="testimony-wrap d-flex"
                      style={{ height: 200 }}
                    >
                      <img src="images/jda.webp" alt="img"></img>
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className="testimony-wrap d-flex"
                      style={{ height: 200 }}
                    >
                      <img src="images/manhattan.png" alt="img"></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };
}
export default withRouter(Home);
