import React from "react";
import { isMobile } from "react-device-detect";
class Projects extends React.Component {
  componentDidMount() {
    var jsElm = document.createElement("script");
    jsElm.src = "js/main.js";
    document.body.appendChild(jsElm);
  }
  render = () => {
    return (
      <>
        <section
          className="hero-wrap hero-wrap-2"
          style={{ backgroundImage: 'url("images/projects1.jpg")' }}
        >
          <div className="overlay" />
          <div className="container">
            <div className="row no-gutters slider-text align-items-center justify-content-center">
              <div className="col-md-9 ftco-animate text-center">
                <h1 className="mb-2 bread">Project</h1>
                <p className="breadcrumbs">
                  <span className="mr-2">
                    <a href="index.html">
                      Home <i className="ion-ios-arrow-forward" />
                    </a>
                  </span>
                  <span>
                    Project <i className="ion-ios-arrow-forward" />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="ftco-section">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div
                  className="project mb-4 img ftco-animate d-flex justify-content-center align-items-center"
                  style={{ backgroundImage: "url(images/books.png)" }}
                >
                  <div className={isMobile ? "overlayProject" : "overlay"} />
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{
                      top: 7,
                      left: 23,
                      position: "absolute",
                      paddingBottom: 10,
                    }}
                  >
                    <h3 className="text text-center">
                      Book Distribution System
                    </h3>
                  </div>
                  <a
                    href="/"
                    className={
                      isMobile
                        ? "btn-siteproject d-flex align-items-center justify-content-center"
                        : "btn-site d-flex align-items-center justify-content-center"
                    }
                  >
                    <span className="icon-subdirectory_arrow_right" />
                  </a>
                  <div className="text text-center p-4">
                    <h3 style={{ fontSize: 20, textAlign: "initial" }}>
                      <br />
                    </h3>
                    <span style={{ fontSize: 12, textAlign: "initial" }}>
                      <p className="description">
                        <span>{`What does it do?`}</span>
                        <br />
                        <span>{`- Manages stock.`}</span>
                        <br />
                        <span>{`- Makes books ready to go, on prior request.`}</span>
                        <br />
                        <span>{`-Expense Detailing.`}</span>
                        <br />
                        <span>{`-Managing database of the partners around the market.`}</span>
                        &nbsp;
                      </p>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="project mb-4 img ftco-animate d-flex justify-content-center align-items-center"
                  style={{ backgroundImage: "url(images/tracking.png)" }}
                >
                  <div className={isMobile ? "overlayProject" : "overlay"} />
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{
                      top: 7,
                      left: 23,
                      position: "absolute",
                      paddingBottom: 10,
                    }}
                  >
                    <h3 className="text text-center">Tracking application</h3>
                  </div>
                  <a
                    href="/"
                    className={
                      isMobile
                        ? "btn-siteproject d-flex align-items-center justify-content-center"
                        : "btn-site d-flex align-items-center justify-content-center"
                    }
                  >
                    <span className="icon-subdirectory_arrow_right" />
                  </a>
                  <div className="text text-center p-4">
                    <h3 style={{ fontSize: 20, textAlign: "initial" }}>
                      <br />
                    </h3>
                    <span style={{ fontSize: 12, textAlign: "initial" }}>
                      <p className="description">
                        {`We have made a tracking application to track the associates end to end. Associates have to just start the application and application will make route of the same.So that the information provided by the associates can be cross checked and the owner can evaluate money for accountability.`}
                      </p>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="project mb-4 img ftco-animate d-flex justify-content-center align-items-center"
                  style={{ backgroundImage: "url(images/Tractor.png)" }}
                >
                  <div className={isMobile ? "overlayProject" : "overlay"} />
                  <div
                    className="d-flex align-items-center justify-content-center title-project"
                    id="title-project"
                    style={{
                      top: 7,
                      left: 23,
                      position: "absolute",
                      paddingBottom: 10,
                    }}
                  >
                    <h3 className="project-title-name">Tractor App</h3>
                  </div>
                  <a
                    href="/"
                    className={
                      isMobile
                        ? "btn-siteproject d-flex align-items-center justify-content-center"
                        : "btn-site d-flex align-items-center justify-content-center"
                    }
                  >
                    <span className="icon-subdirectory_arrow_right" />
                  </a>
                  <div className="text text-center p-4">
                    <h3 style={{ fontSize: 20, textAlign: "initial" }}>
                      <br />
                    </h3>
                    <span style={{ fontSize: 12, textAlign: "initial" }}>
                      <p className="description">
                        {`We have provided the basis of categorised clients with multiple stores and user(admin/ operator). Inventory management has been done with no hustle at all. And we are also showing reports at various level so that admin can plan his/her day ahead.`}{" "}
                      </p>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="project mb-4 img ftco-animate d-flex justify-content-center align-items-center"
                  style={{ backgroundImage: "url(images/loan.webp)" }}
                >
                  <div className={isMobile ? "overlayProject" : "overlay"} />
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{
                      top: 7,
                      left: 23,
                      position: "absolute",
                      paddingBottom: 10,
                    }}
                  >
                    <h3 className="text text-center">Loan by Jewellery Shop</h3>
                  </div>
                  <a
                    href="/"
                    className={
                      isMobile
                        ? "btn-siteproject d-flex align-items-center justify-content-center"
                        : "btn-site d-flex align-items-center justify-content-center"
                    }
                  >
                    <span className="icon-subdirectory_arrow_right" />
                  </a>
                  <div className="text text-center p-4">
                    <h3 style={{ fontSize: 20, textAlign: "initial" }}>
                      <br />
                    </h3>
                    <span style={{ fontSize: 12, textAlign: "initial" }}>
                      <p className="description">
                        We have provided to client on taking the details and
                        performing calculations <br />
                        (Simple and Compound Interests) implicable to their
                        clients, with ease and make the things work paperless.
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };
}
export default Projects;
