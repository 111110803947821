import React from "react";
import { withRouter } from "react-router-dom";
import { isMobile } from "react-device-detect";
class Header extends React.Component {
  render = () => {
    const props = this.props;
    const path = props.location.pathname;
    return (
      <>
        <nav
          style={{ padding: 0 }}
          className="navbar fixed-top navbar-light bg-light d-lg-none d-xl-block header"
        >
          <div className="bg-top navbar-light">
            <div
              className="container"
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "between",
              }}
            >
              <div
                className="col-md-5 d-flex align-items-center "
                style={{ padding: "15px 0px" }}
              >
                <a
                  className="navbar-brand d-none d-lg-block d-xl-block"
                  href="/"
                >
                  <div className="heading-logo">
                    <img src="img/favicon.png" alt="img"></img>
                  </div>
                </a>
                <a
                  className="navbar-brand d-none d-lg-block d-xl-block"
                  href="/"
                >
                  QuickServe IT Solution
                </a>
                <a className="navbar-brand d-lg-none d-xl-none" href="/">
                  <img src="img/favicon.png" alt="img"></img>
                </a>
                <a
                  className="navbar-brand d-lg-none d-xl-none"
                  href="/"
                  style={{ fontSize: "20px" }}
                >
                  QuickServe IT Solution
                </a>
              </div>
              <div
                className="col-lg-7 d-none d-lg-block d-xl-block"
                style={{ float: "right" }}
              >
                <div className="row d-flex">
                  <div
                    className="col-md d-flex topper align-items-center align-items-stretch"
                    style={{ padding: "15px 90px" }}
                  >
                    <div className="icon d-flex justify-content-center align-items-center">
                      <span className="icon-paper-plane" />
                    </div>
                    <div className="text">
                      <span>Email</span>
                      <span>{window.mail}</span>
                    </div>
                  </div>
                  <div
                    className="col-md d-flex topper align-items-center align-items-stretch"
                    style={{ padding: "15px 0px" }}
                  >
                    <div className="icon d-flex justify-content-center align-items-center">
                      <span className="icon-phone2" />
                    </div>
                    <div className="text">
                      <span>Call</span>
                      <a href={`tel:${window.phone}`}>
                        Call Us: {"+91 62676 24548"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <nav
            style={{ width: "-webkit-fill-available" }}
            className="navbar navbar-expand-lg navbar-dark bg-dark ftco-navbar-light"
            id="ftco-navbar"
          >
            <div className="container d-flex align-items-center">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#ftco-nav"
                aria-controls="ftco-nav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-menu" /> Menu
              </button>

              <div className="d-lg-none d-xl-none">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#ftco-more-nav"
                  aria-controls="ftco-more-nav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="icon-more_vert" />
                </button>
              </div>
              <div
                className="collapse navbar-light d-lg-none d-xl-none"
                id="ftco-more-nav"
              >
                <ul className="navbar-nav mr-auto">
                  <li
                    className=" nav-item "
                    style={{ color: "white", display: "flex" }}
                  >
                    <span style={{ padding: 10 }}>
                      <img src="img/favicon.png" alt="img"></img>
                    </span>
                    <span style={{ padding: 10 }}>QuickServe IT Solution</span>
                  </li>
                  <li
                    className=" nav-item "
                    style={{ color: "white", display: "flex" }}
                  >
                    <div
                      className="icon d-flex justify-content-center align-items-center"
                      style={{ padding: 10 }}
                    >
                      <span className="icon-paper-plane" />
                    </div>
                    <div className="text" style={{ padding: 10 }}>
                      <span>Email: {window.mail}</span>
                    </div>
                  </li>
                  <li
                    className=" nav-item "
                    style={{ color: "white", display: "flex" }}
                  >
                    <div
                      className="icon d-flex justify-content-center align-items-center"
                      style={{ padding: 10 }}
                    >
                      <span className="icon-phone2" />
                    </div>
                    <div
                      className="text"
                      style={{ padding: 10, color: "white" }}
                    >
                      <a
                        href={`tel:${window.phone}`}
                        style={{ color: "white" }}
                      >
                        Call Us: {"+91 62676 24548"}
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="collapse navbar-collapse" id="ftco-nav">
                <ul
                  className="navbar-nav"
                  style={{ marginLeft: isMobile ? "mr-auto" : -55 }}
                >
                  <li
                    className=" nav-item "
                    data-toggle={isMobile ? "collapse" : ""}
                    data-target="#ftco-nav"
                    aria-controls="ftco-nav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span
                      style={{
                        color: path === "/home" ? "#1b9ce3" : null,
                        cursor: "pointer",
                      }}
                      onClick={() => props.history.push("/home")}
                      className="nav-link"
                    >
                      Home
                    </span>
                  </li>
                  <li
                    className=" nav-item "
                    data-toggle={isMobile ? "collapse" : ""}
                    data-target="#ftco-nav"
                    aria-controls="ftco-nav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span
                      style={{
                        color: path === "/about" ? "#1b9ce3" : null,
                        cursor: "pointer",
                      }}
                      onClick={() => props.history.push("/about")}
                      className="nav-link"
                    >
                      About
                    </span>
                    {/* <a href="/about" className="nav-link">
                    About
                  </a> */}
                  </li>
                  <li
                    className=" nav-item "
                    data-toggle={isMobile ? "collapse" : ""}
                    data-target="#ftco-nav"
                    aria-controls="ftco-nav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span
                      style={{
                        color: path === "/projects" ? "#1b9ce3" : null,
                        cursor: "pointer",
                      }}
                      onClick={() => props.history.push("/projects")}
                      className="nav-link"
                    >
                      Projects
                    </span>
                  </li>
                  <li
                    className=" nav-item "
                    data-toggle={isMobile ? "collapse" : ""}
                    data-target="#ftco-nav"
                    aria-controls="ftco-nav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span
                      style={{
                        color: path === "/services" ? "#1b9ce3" : null,
                        cursor: "pointer",
                      }}
                      onClick={() => props.history.push("/services")}
                      className="nav-link"
                    >
                      Services
                    </span>
                  </li>
                  {/* <li className={`navve"}`} style={{backgroundColor:${path === "/b?"#1b9ce3":null}}>
                  <a href="/blog" className="nav-link">
                    Blog
                  </a>
                </li> */}
                  <li
                    className=" nav-item "
                    data-toggle={isMobile ? "collapse" : ""}
                    data-target="#ftco-nav"
                    aria-controls="ftco-nav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span
                      style={{
                        color: path === "/contact" ? "#1b9ce3" : null,
                        cursor: "pointer",
                      }}
                      onClick={() => props.history.push("/contact")}
                      className="nav-link"
                    >
                      Contact
                    </span>
                  </li>
                  <li
                    className=" nav-item "
                    data-toggle={isMobile ? "collapse" : ""}
                    data-target="#ftco-nav"
                    aria-controls="ftco-nav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span
                      style={{
                        color: path === "/team" ? "#1b9ce3" : null,
                        cursor: "pointer",
                      }}
                      onClick={() => props.history.push("/team")}
                      className="nav-link"
                    >
                      Team
                    </span>
                  </li>
                  <li
                    className=" nav-item "
                    data-toggle={isMobile ? "collapse" : ""}
                    data-target="#ftco-nav"
                    aria-controls="ftco-nav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span
                      style={{
                        color: path === "/career" ? "#1b9ce3" : null,
                        cursor: "pointer",
                      }}
                      onClick={() => props.history.push("/career")}
                      className="nav-link"
                    >
                      Career
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </nav>
        <div
          className="d-none d-lg-block d-xl-block"
          style={{ height: "123px" }}
        />
        <div className="d-lg-none d-xl-none" style={{ height: "94px" }} />
      </>
    );
  };
}
export default withRouter(Header);
