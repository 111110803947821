import React from "react";
import Home from "./pages/Home";
import About from "./pages/About";
import Projects from "./pages/Projects";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import OurTeam from "./pages/Team";
import Career from "./pages/Career/Career";

const Routes = () => {
  return [
    {
      path: "/home",
      component: <Home />,
    },
    {
      path: "/about",
      component: <About />,
    },
    {
      path: "/projects",
      component: <Projects />,
    },
    {
      path: "/services",
      component: <Services />,
    },
    {
      path: "/contact",
      component: <Contact />,
    },
    {
      path: "/team",
      component: <OurTeam />,
    },
    {
      path: "/career",
      component: <Career />,
    },
  ];
};

export default Routes;
