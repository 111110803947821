import React from "react";

function Address() {
  return (
    <div className="col-lg-3 col-md-4" style={{ paddingTop: 20 }}>
      <div className="block-23 mb-3">
        <ul>
          <li>
            <span className="icon icon-map-marker" style={{ color: "black" }} />
            <span className="text" style={{ color: "black" }}>
              Plot no 176, Aliganj Mahanagar Gomti Nagar, Lucknow, Uttar Pradesh
              226006
            </span>
          </li>
          <li>
            <span className="icon icon-phone" style={{ color: "black" }} />
            <span className="text" style={{ color: "black" }}>
              Landline: 0522 2200268
            </span>
          </li>
          <li>
            <span className="icon icon-map-marker" style={{ color: "black" }} />
            <span className="text" style={{ color: "black" }}>
              Budha talab, opposite sitala mandir, kankali para, Raipur, 492001,
              Chhattisgarh
            </span>
          </li>
          <li>
            <span className="icon icon-envelope" style={{ color: "black" }} />
            <span className="text" style={{ color: "black" }}>
              info@quickserveits.in
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Address;
