import React from "react";

class Services extends React.Component {
  state = { displayAI: "none", displayCyber: "none" };
  handleReadmoreAI = () => {
    this.setState({ displayAI: "block" });
  };
  handleReadmoreCyber = () => {
    this.setState({ displayCyber: "block" });
  };
  componentDidMount() {
    var jsElm = document.createElement("script");
    jsElm.src = "js/main.js";
    document.body.appendChild(jsElm);
  }
  render = () => {
    return (
      <>
        <section
          className="hero-wrap hero-wrap-2"
          style={{ backgroundImage: 'url("images/services1.png")' }}
        >
          <div className="overlay" />
          <div className="container">
            <div className="row no-gutters slider-text align-items-center justify-content-center">
              <div className="col-md-9 ftco-animate text-center">
                <h1 className="mb-2 bread">Services</h1>
                <p className="breadcrumbs">
                  <span className="mr-2">
                    <a href="index.html">
                      Home <i className="ion-ios-arrow-forward" />
                    </a>
                  </span>
                  <span>
                    Services <i className="ion-ios-arrow-forward" />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="ftco-section ftco-no-pb">
          <div className="container">
            <div className="row d-flex">
              <div className="col-md-5 order-md-last wrap-about align-items-stretch">
                <div className="wrap-about-border ftco-animate">
                  <img
                    src="img/product-features.png"
                    alt="img"
                    className="wow fadeInLeft"
                  />
                  <div
                    className="img"
                    style={{ backgroundImage: "url(img/product-features.png)" }}
                  />
                  <div className="text">
                    <h3>Read Our Success Story for Inspiration</h3>
                    <p>
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia, there live the blind
                      texts. Separated they live in Bookmarksgrove right at the
                      coast of the Semantics, a large language ocean.
                    </p>
                    <p>
                      On her way she met a copy. The copy warned the Little
                      Blind Text, that where it came from it would have been
                      rewritten a thousand times and everything that was left
                      from its origin would be the word.
                    </p>
                    <p>
                      <a href="#" className="btn btn-primary py-3 px-4">
                        Contact us
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-7 wrap-about pr-md-4 ftco-animate">
                <h2 className="mb-4">Our Main Features</h2>

                <div className="row mt-5">
                  <div className="col-lg-6">
                    <div className="services active text-center">
                      <div className="icon mt-2 d-flex justify-content-center align-items-center">
                        <span className="flaticon-collaboration" />
                      </div>
                      <div className="text media-body">
                        <h3>Organization</h3>
                        <p>
                          Identifying the resources and dividing the modules
                          into small chunks. To inculcate a common objective.
                        </p>
                      </div>
                    </div>
                    <div className="services text-center">
                      <div className="icon mt-2 d-flex justify-content-center align-items-center">
                        <span className="flaticon-analysis" />
                      </div>
                      <div className="text media-body">
                        <h3>Risk Analysis</h3>
                        <p>
                          We always ensure that we take only calculated risk. As
                          you start implementing new ideas.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="services text-center">
                      <div className="icon mt-2 d-flex justify-content-center align-items-center">
                        <span className="flaticon-search-engine" />
                      </div>
                      <div className="text media-body">
                        <h3>Marketing Strategy</h3>
                        <p>
                          Planning ahead to make most out of anything is really
                          essential. Hence, We plan, we execute, we deliver.
                        </p>
                      </div>
                    </div>
                    <div className="services text-center">
                      <div className="icon mt-2 d-flex justify-content-center align-items-center">
                        <span className="flaticon-handshake" />
                      </div>
                      <div className="text media-body">
                        <h3>Capital Market</h3>
                        <p>
                          It's always high time on stocks and requires
                          experience for when it's time to do the investments.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className="ftco-section">
          <div className="container">
            <div className="row justify-content-center mb-5 pb-2">
              <div className="col-md-8 text-center heading-section ftco-animate">
                <h2 className="mb-4">Our Best Services</h2>
              </div>
              <p>
                Our passion is to solve your business problems with unique web
                application development - whether that is increasing leads or
                helping internal operations. But we don’t stop with your
                website. In addition to custom website development and design,
                we offer a range of search engine optimization, content
                marketing, branding, and related services.
              </p>
            </div>
            <div className="row no-gutters">
              <div className="col-lg-4 d-flex">
                <div className="services-2 noborder-left text-center ftco-animate">
                  <div className="icon mt-2 d-flex justify-content-center align-items-center">
                    <span className="flaticon-analysis" />
                  </div>
                  <div className="text media-body">
                    <h3>Customer Relationship Management</h3>
                    <p>
                      Creating new customers and maintaining current is the main
                      focus of every business, for this is stability and growth.
                    </p>
                    <button
                      className="btn btn-primary buttonHover"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleReadmoreAI();
                      }}
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="services-2 text-center ftco-animate">
                  <div className="icon mt-2 d-flex justify-content-center align-items-center">
                    <span className="flaticon-business" />
                  </div>
                  <div className="text media-body">
                    <h3>Application & Software Development</h3>
                    <p>
                      We are interested in developing your business through a
                      professional application/software development process.
                    </p>
                    <button
                      className="btn btn-primary buttonHover"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleReadmoreAI();
                      }}
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="services-2 text-center ftco-animate">
                  <div className="icon mt-2 d-flex justify-content-center align-items-center">
                    <span className="flaticon-insurance" />
                  </div>
                  <div className="text media-body">
                    <h3>Enterprise Resource & Planning</h3>
                    <p>
                      We specialize in the design, development and
                      implementation of intelligent customized enterprise
                      solutions.
                      <br />
                      <br />
                    </p>
                    <button
                      className="btn btn-primary buttonHover"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleReadmoreAI();
                      }}
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="services-2 noborder-left noborder-bottom text-center ftco-animate">
                  <div className="icon mt-2 d-flex justify-content-center align-items-center">
                    <span className="flaticon-money" />
                  </div>
                  <div className="text media-body">
                    <h3>Search Engine Optimization (SEO)</h3>
                    <p>
                      We have an excellent team of search engine optimizers and
                      web masters who are dedicated on SEO projects and Internet
                      marketing.
                    </p>
                    <button
                      className="btn btn-primary buttonHover"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleReadmoreAI();
                      }}
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="services-2 text-center noborder-bottom ftco-animate">
                  <div className="icon mt-2 d-flex justify-content-center align-items-center">
                    <span className="flaticon-rating" />
                  </div>
                  <div className="text media-body">
                    <h3>Artificial Intelligence (AI)</h3>
                    <p>
                      We create powerful and intelligent solutions that are
                      infused with high-end Artificial Intelligence development
                      services that not only integrate seamlessly with your
                      business processes but are self-aware of
                    </p>
                    <p style={{ display: this.state.displayAI }}>
                      boosting operational efficiency and business growth.
                    </p>
                    <button
                      className="btn btn-primary buttonHover"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleReadmoreAI();
                      }}
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="services-2 text-center noborder-bottom ftco-animate">
                  <div className="icon mt-2 d-flex justify-content-center align-items-center">
                    <span className="flaticon-search-engine" />
                  </div>
                  <div className="text media-body">
                    <h3>Cyber Security</h3>
                    <p className="wow fadeInRight" data-wow-duration="0.5s">
                      Different size organizations cope with different problems,
                      but all have employees as the weak link in their IT
                      security. The challenges of creating and running an
                      awareness program vary depending on the
                    </p>
                    <p style={{ display: this.state.displayCyber }}>
                      amount of employees. Select from the varied available
                      options that we will suggest best practices for your
                      size/type of organization.
                    </p>
                    <button
                      className="btn btn-primary buttonHover1 "
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleReadmoreCyber();
                      }}
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };
}
export default Services;
