import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";

const init = {
  apiKey: "AIzaSyBW3e8mBI1AIVcMLYIw4gO0dst1PSMrGD4",
  authDomain: "quickserveits.firebaseapp.com",
  databaseURL: "https://quickserveits.firebaseio.com",
  projectId: "quickserveits",
  storageBucket: "quickserveits.appspot.com",
  messagingSenderId: "634565372776",
  appId: "1:634565372776:web:abd84c78de98db2481c4dd",
  measurementId: "G-0RZ33RSMNZ",
};

firebase.initializeApp(init);
firebase.analytics();
firebase.firestore().enablePersistence();

const db = firebase.firestore();

export default db;
