import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// import "./css/open-iconic-bootstrap.min.css";
// import "./css/animate.css";
// import "./css/owl.carousel.min.css";
// import "./css/owl.theme.default.min.css";
// import "./css/magnific-popup.css";
// import "./css/aos.css";
// import "./css/ionicons.min.css";
// import "./css/flaticon.css";
// import "./css/icomoon.css";
// import "./css/style.css";

window.address =
  "Budha talab, opposite sitala mandir, kankali para, Raipur, 492001, Chhattisgarh";
window.companyName = "Quickserve It Solution";
window.mail = "info@quickserveits.in";
window.phone = "0522 2200268";
window.raipurPhone = "+91 62676 24548";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
