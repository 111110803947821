import React from "react";

import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import db from "../../hoc/Firebase";
const initState = { name: "", subject: "", msg: "", email: "" };
class ContactUs extends React.Component {
  state = { ...initState, open: false };
  submitData = (e) => {
    const metaInfo = {
      platform: navigator.platform,
      connection: navigator.connection
        ? navigator.connection.effectiveType
          ? navigator.connection.effectiveType
          : navigator.connection.type
          ? navigator.connection.type
          : null
        : null,
      maxTouchPoints: navigator.maxTouchPoints,
      userAgent: navigator.userAgent,
    };
    db.collection("contactUs")
      .add({ ...this.state, metaInfo, createdAt: new Date() })
      .then(() => this.state.name && this.rollbackStates())
      .catch(() => this.state.name && this.rollbackStates());
  };
  rollbackStates = () => {
    this.setState(initState);
    this.handleClick();
  };
  handleClick = () => {
    this.setState({ open: true });
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ open: false });
  };

  render = () => (
    <div className="col-lg-5 col-md-8">
      <div className="form">
        <div id="sendmessage">Your message has been sent. Thank you!</div>
        <div id="errormessage" />
        <form
          onSubmit={(e) => {
            this.submitData(e);
            return false;
          }}
          className="contactForm"
        >
          <div className="form-row">
            <div className="form-group col-lg-6">
              <input
                onChange={(e) => this.setState({ name: e.target.value })}
                value={this.state.name}
                type="text"
                name="name"
                className="form-control"
                id="name"
                placeholder="Your Name"
                data-rule="minlen:4"
                data-msg="Please enter at least 4 chars"
              />
              <div className="validation" />
            </div>
            <div className="form-group col-lg-6">
              <input
                onChange={(e) => this.setState({ email: e.target.value })}
                value={this.state.email}
                type="email"
                className="form-control"
                name="email"
                id="email"
                placeholder="Your Email"
                data-rule="email"
                data-msg="Please enter a valid email"
              />
              <div className="validation" />
            </div>
          </div>
          <div className="form-group">
            <input
              onChange={(e) => this.setState({ subject: e.target.value })}
              value={this.state.subject}
              type="text"
              className="form-control"
              name="subject"
              id="subject"
              placeholder="Subject"
              data-rule="minlen:4"
              data-msg="Please enter at least 8 chars of subject"
            />
            <div className="validation" />
          </div>
          <div className="form-group">
            <textarea
              onChange={(e) => this.setState({ msg: e.target.value })}
              value={this.state.msg}
              className="form-control"
              name="message"
              rows={5}
              data-rule="required"
              data-msg="Please write something for us"
              placeholder="Message"
            />
            <div className="validation" />
          </div>
          <div className="text-center">
            <button
              type="submit"
              title="Send Message"
              className="btn btn-primary py-2 px-3"
            >
              Send Message
            </button>
          </div>
        </form>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={this.state.open}
        autoHideDuration={6000}
        onClose={this.handleClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">We have received your message.</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={this.handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </div>
  );
}

export default ContactUs;
