import React from "react";

import Header from "./components/Header";
import Footer from "./components/Footer";

import Routes from "./Routes";

import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

const routes = Routes();

class App extends React.Component {
  render = () => {
    return (
      <>
        <Router>
          <Header {...this.props} />
          <div>
            <Redirect exact from="/" to="/home" />
            {routes.map((ele) => (
              <Route key={ele.path} path={ele.path}>
                {ele.component}
              </Route>
            ))}
          </div>
          <Footer />
        </Router>
      </>
    );
  };
}

export default App;
