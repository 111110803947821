import React from "react";
class Career extends React.Component {
  componentDidMount() {
    var jsElm = document.createElement("script");
    jsElm.src = "js/main.js";
    document.body.appendChild(jsElm);
  }
  render = () => {
    return (
      <>
        <section
          className="hero-wrap hero-wrap-2"
          style={{ backgroundImage: 'url("images/career.jpg")' }}
        >
          <div className="overlay" />
          <div className="container">
            <div className="row no-gutters slider-text align-items-center justify-content-center">
              <div className="col-md-9 ftco-animate text-center">
                <h1 className="mb-2 bread">Career</h1>
                <p className="breadcrumbs">
                  <span className="mr-2">
                    <a href="index.html">
                      Home <i className="ion-ios-arrow-forward" />
                    </a>
                  </span>
                  <span>
                    Career <i className="ion-ios-arrow-forward" />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="ftco-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <p>
                  QSITS focuses on providing the employees the best opportunity
                  that enables them to enhance their skills and grow in their
                  career. We provide our employees various training courses that
                  include project related training. This helps them to sharpen
                  their technical skills. We provide numerous and frequent job
                  opportunities that enable the employees to keep growing in
                  their career path facing the challenges in various fields and
                  sustain your accomplishments.
                </p>
                <p>
                  Our working environment enable you to work at your best to
                  climb up the career ladder at ease and with involvement
                  contributing to the organization's growth. We aim at matching
                  you job and your career. Our fresh talents have gone their way
                  up in their career to be a part of Smartweb and so shall you.
                  We look forward to have you as a part of our family. Thank you
                  for choosing Quick Serve IT solutions... gateway to your
                  future.!!!
                </p>
                <p>Current Opening !!</p>
                <div className="career-coming-soon">
                  <p>Coming soon…</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };
}
export default Career;
